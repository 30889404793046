<template>
  <div class="mb-3">
    <CRow class="my-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
    </CRow>

    <CRow class="mt-2">
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable"
                :items="computedBlList"
                :fields="blfields"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loading"
                sorter
            >
            
                <template #options="{ item }">
                    <td class="text-center">
                        <CButton
                        square
                        size="sm"
                        color="watch"
                        class="d-flex align-items-center"
                        @click="toggleContainerEdit(item)"
                        v-c-tooltip="{
                            content: $t('label.bl'),
                            placement: 'top-end'
                        }"
                        >
                        <CIcon name="eye" />
                        </CButton>
                    
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
    </CRow>
    <ModalBlContainer
        :modal.sync="ModalBlContainer"
        :ContainerItem="ContainerItem"
        :items ="dataBl"
        :title="titleModal"
        :ContainerCargoId="dataContainer?.HeaderJson?.[0]?.CargoId"
        :isEdit="true"
        :fgInfo="true"
        @close="closeModal"
    />
  </div>
</template>
<script>
    import General from '@/_mixins/general';
    import ModalBlContainer from '@/pages/yard-management/container/bl/modal-bl-container';
    import { mapState } from 'vuex';
    import ReportSearchContainer from '@/_mixins/reportsSearchContainer';
    import ENUM from '@/_store/enum';
    import { DateFormater, NumberFormater, trimRemoveSpecialCharacters, removeTags } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            Items: [],
            loading: false,
            titleModal: '',
            ModalBlContainer: false,
            ContainerItem:{},
            dataBl: [],
        };
    }

    //methods
    function toggleContainerEdit(item) {
        this.ContainerItem = item;
        this.titleModal = `${this.$t('label.bl')}: ${item?.BlNro ?? ''}`
        this.ModalBlContainer = !this.ModalBlContainer;
    }

    function closeModal(){
        this.ModalBlContainer = false;
        this.ContainerItem = {};
        this.titleModal = '';
    }


    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        this.$store.state.containerSearch.loading = ENUM.LOADING;
        let tableHeader = {label:[], key:[]};
        let rowDataFormated = [];

        this.blfields.map(item=>{   
            if (item.key!='options') {
                tableHeader.label.push(item.label);
                tableHeader.key.push(item.key);
            }
        })

        if(this.nulo!="null" && this.dataBl.length !== 0) {
            await this.generateExcel(this.computedBlList, this.$t('label.bl'), valor, tableHeader.label, false, tableHeader, 'SERVICIOS LOGISTICOS OCK', this.$store.state.containerSearch.dataContainer);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.containerSearch.loading = ENUM.LOADED;
    }

    //computeds
    function computedBlList() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return  this.dataBl.map((item) => {
            return {
                ...item,
                Nro: item.IdX,
                BookingNro: item.BookingNro ? item.BookingNro : 'N/A',
                CustomOperation:  item[`CustomOperationDs${_lang}`] ? item[`CustomOperationDs${_lang}`] : 'N/A',
                CustomBrokerName: item.CustomBrokerName ?? 'N/A',
                ConsigneeName: item.ConsigneeName ?? 'N/A',
                Dua: item.Dua ?  item.Dua : 'N/A',
                Procedence: item.Procedence ? item.Procedence : 'N/A',
                Quantity: NumberFormater.formatNumber(item.Quantity, 2),
                ReceptionBpAct: item.ReceptionBpAct ? item.ReceptionBpAct : 'N/A',
                Weigth: NumberFormater.formatNumber(item?.Weigth, 2),
                Volumen: NumberFormater.formatNumber(item?.Volumen, 2),
                DescriptionOfGoods: trimRemoveSpecialCharacters(removeTags(item.DescriptionOfGoods)) ?? 'N/A',
                DescriptionOfGoodsShowEdit: item.DescriptionOfGoods,
                TransaLogin: item.TransaLogin ?? 'N/A',
                FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate): 'N/A',
                _classes: 'color-gradient',
                _style: `background: ${item?.YardCargoBlStatusColor}`,
            }
        });
    }

    function blfields(){ 
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'Nro', label: '#', _style: 'min-width: 60px; text-align:center', _classes: 'text-center', filter: false },
            { key: 'BlNro',label: this.$t('label.bl'), _style:'min-width: 120px;', _classes: 'text-center' },
            { key: 'TpBlName',label: this.$t('label.type'), _style:'min-width: 120px;', _classes: 'text-center' },
            { key: 'ConsigneeName', label: this.$t('label.consignee'), _style:'min-width:150px;', _classes: 'text-center' },
            { key: 'CustomBrokerName', label: this.$t('label.customBroker'),  _style:'min-width:170px;', _classes:"text-center"  },
            { key: 'CustomOperation', label: this.$t('label.customsRegime'),  _style:'min-width:220px;', _classes:"text-center"  },
            { key: 'BookingNro', label: this.$t('label.nBooking'),  _classes: 'text-center', _style: 'min-width:180px;' },
            { key: 'DescriptionOfGoods', label: this.$t('label.inventoryContains'), _style:'min-width:220px;', _classes:"text-center" },
            { key: 'Dua', label: this.$t('label.nDua'), _style:'min-width:140px;', _classes:"text-center" },
            { key: 'ReceptionBpAct', label: this.$t('label.BolipuertoCertificateEntry'), _style:'min-width:300px;', _classes:"text-center" },
            { key: 'Procedence', label: this.$t('label.Origin'),  _style:'min-width:150px;', _classes:"text-center"  },
            { key: 'Weigth', label: this.$t('label.weight')+' '+('(KGM)'), _style:'min-width: 140px;', _classes: 'text-center' },
            { key: 'Volumen', label: this.$t('label.volume')+' '+('(M³)'), _style:'min-width: 140px;', _classes: 'text-center' },
            { key: 'Quantity', label: this.$t('label.quantity'), _style:'min-width: 120px;', _classes: 'text-center' },
            { key: `BlStatus${_lang}`, label: this.$t('label.blStatus'), _style:'min-width:200px;', _classes:"text-center text-bold" },
            { key: `YardCargoBlStatus${_lang}`, label: this.$t('label.statusYardWarehouse'), _style:'min-width:180px;', _classes:"text-center text-bold" },
            { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center" },
            { key: 'FormatedDate', label: this.$t('label.date'), _style:'min-width: 180px;', _classes:"text-center" },
        ];
    }

    export default{
        name: 'bl-list',
        data,
        mixins: [General, ReportSearchContainer],
        components: {
            ModalBlContainer,
        },
        methods: {
            toggleContainerEdit,
            closeModal,
            onBtnExport
        },
        computed: {
            blfields,
            computedBlList,
            ...mapState({
                dataContainer: state => state.containerSearch.dataContainer,
            })
        },
        watch:{
            dataContainer: function (newValue) {
                if (Object.keys(newValue).length > 0 && this.$store.state.containerSearch.dataContainer?.BlJson){
                    this.dataBl = this.$store.state.containerSearch.dataContainer?.BlJson ?? [];
                }
                    
                else
                    this.dataBl = [];
                
            },
        }
    }
</script>
<style lang="scss">

    .icon{
        background-color: null; 
        border: 0px;
    }

    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>